/**
 * 首页商家商城基本信息API
 */

import request from '@/utils/request'
import {api} from '@/../ui-domain'

/**
 * 获取首页统计信息
 * @returns {Promise<any>}
 */
export function getDashboardData() {
  return request({
    url: 'seller/statistics/dashboard/shop',
    method: 'get',
    loading: false
  })
}

/**
 * 获取企业今日数据
 * @returns {Promise<any>}
 */
export function getCurrendData() {
  return request({
    url: 'seller/statistics/enterprise/todayData',
    method: 'get',
    loading: false
  })
}

/**
 * 获取商城公告
 * @param params
 * @returns {Promise<any>}
 */
export function getNotice(type, params) {
  return request({
    url: `${api.base}/pages/article-categories/${type}/articles`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取首页平台联系方式
 * @param params
 * @returns {Promise<any>}
 */
export function getConcate(params) {
  return request({
    url: `${api.base}/pages/${params.position}/articles`,
    method: 'get',
    loading: false
  })
}

/**
 * 首页=》系统首页=》订单统计
 * @param params
 * @returns {Promise<any>}
 */
export function getOrderNum(params) {
  return request({
    url: `/seller/statistics/dashboard/num`,
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询企业当前等级表
 * @param params
 * @returns {Promise<any>}
 */
export function getLv() {
  return request({
    url: `/seller/statistics/dashboard/getLv`,
    method: 'get',
    loading: false,
  })
}

