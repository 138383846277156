<template>
  <div class="dashboard-container" v-loading="loading">
    <template>
      <el-row :gutter="20" type="flex" justify="space-around">
        <el-col :span="12" style="padding-right: 0">
          <el-card style="margin-bottom: 10px">
            <div v-if="is_JdSupplier">
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/icon-supply1.png" alt=""/>
                </div>
                <div class="header-title col">待发货</div>
              </div>
              <div class="cs" style="color:#FF1522">0</div>
            </div>
            <router-link
              v-else
              to="/order/union-order-list?type=1"
              tag="div"
              style="cursor: pointer;"
            >
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/icon-supply1.png" alt=""/>
                </div>
                <div class="header-title col">待发货</div>
              </div>
              <div class="cs" style="color:#FF1522">
                {{ dashBoardData.wait_ship_order_num || 0 }}
                <span style="font-size: 18px;">(含拣货中状态)</span>
              </div>
            </router-link>
          </el-card>
          <el-card>
            <router-link
              :to="{path: '/order/refund-list', query: {type: 1}}"
              tag="div"
              style="cursor: pointer;"
            >
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/icon-supply3.png" alt=""/>
                </div>
                <div class="header-title col">待售后</div>
              </div>
              <div class="cs" style="color:#059FEE">
                {{ dashBoardData.after_sale_order_num || 0 }}
              </div>
            </router-link>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card style="margin-bottom: 10px">
            <router-link
              to="/order/union-order-list?type=2"
              tag="div"
              style="cursor: pointer;"
            >
              <div class="header row no-gutters align-items-center">
                <div class="col-auto">
                  <img src="../../assets/icon-supply2.png" alt=""/>
                </div>
                <div class="header-title col">昨日订单数</div>
              </div>
              <div class="cs" style="color:#00D600">
                {{ dashBoardData.yesterday_all_order_num || 0 }}
              </div>
            </router-link>
          </el-card>
          <el-card>
            <div class="header row no-gutters align-items-center">
              <div class="col-auto">
                <img src="../../assets/icon-supply4.png" alt=""/>
              </div>
              <div class="col header-title">昨日销售总额</div>
            </div>
            <div class="cs" style="color:#FE9D01">
              {{ dashBoardData.yesterday_pay_order_total || 0 }}
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="quickLink">
        <div class="title">运营快捷入口</div>
        <div class="linkBox">
          <router-link to="/goods/goods-list">
            <img src="../../assets/yingyingzhongxin.png"/>商品库
          </router-link>
          <router-link to="/order/union-order-list">
            <img src="../../assets/dingdanguanli.png"/>订单管理
          </router-link>
          <router-link to="/finance/overview">
            <img src="../../assets/caiwuguanli.png"/>财务管理
          </router-link>
          <router-link to="/setting/safeSetting">
            <img src="../../assets/qiyeshengji.png"/>安全设置
          </router-link>
        </div>
      </div>
      <div class="line">
        <div class="title">订单统计</div>
        <div class="store-index-content order">
          <div class="orderLeft">
            <div class="orderBox">
              <h2>本月订单总数</h2>
              <h3>{{ orderTotalNum }}</h3>
              <!-- <p>
                <span class="red">10%</span>同比上周
              </p> -->
            </div>
            <!-- <div class="orderBox">
              <h2>本周订单总数</h2>
              <h3>10000</h3>
              <p>
                <span class="green">10%</span>同比上周
              </p>
            </div> -->
          </div>
          <div class="orderRight">
            <div class="orderTab">
              <div class="tabBtn">
                <!-- <span>今日</span>
                                <span>本周</span>
                <span>本月</span>-->
                <el-date-picker
                  v-model="selectMonth"
                  type="month"
                  placeholder="请选择月"
                  @change="handleShopSumaryStatistics"
                  value-format="yyyy-MM"
                ></el-date-picker>
              </div>
              <h4>{{ name }}</h4>
            </div>
            <div id="trafficStatistics" style="height: 360px"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="false">
      <!--商家基本信息-->
      <el-row :gutter="20" type="flex" justify="space-around" v-if="shop_info">
        <el-col :span="24">
          <div class="grid-content bg-purple-light">
            <!--logo-->
            <div class="logo">
              <div class="shop-img-icon">
                <img v-if="shop_info.shop_logo" :src="shop_info.shop_logo"/>
                <img
                  v-if="!shop_info.shop_logo"
                  src="../../assets/logo_images/icon-no-shop-logo.png"
                />
                <span class="to-change-shop-icon" @click="toChangeShopIcon">更改图标</span>
              </div>
              <el-upload
                class="upload-demo"
                style="display: none;"
                :action="`${MixinUploadApi}?scene=shop`"
                :limit="1"
                :file-list="fileList"
                :before-upload="handleShopLogoBefore"
                :on-success="uploaded"
              >
                <button style="visibility: hidden;" ref="uploadBtn"></button>
              </el-upload>
            </div>
            <!--文字信息-->
            <div class="shop-info">
              <div class="shop-info-basic">
                <span>{{ shop_info.shop_name }}</span>
                <span>（用户名：{{ user_info.username }}）</span>
                <!--是否是平台自营-->
                <span v-if="parseInt(shop_info.self_operated) === 1">平台自营</span>
              </div>
              <div class="shop-info-credit">
                <span>卖家信用：{{ shop_info.shop_credit }} &nbsp;</span>
                <div></div>
                <span>
                  店铺地址：{{ shop_info.shop_province }}
                  {{ shop_info.shop_city }}
                  {{ shop_info.shop_county }}
                  {{ shop_info.shop_town }}
                </span>
              </div>
            </div>
            <!--动态评分-->
            <div class="score">
              <p>店铺动态评分</p>
              <div class="active-score">
                <div>
                  <span>描述</span>
                  <span>{{ shop_info.shop_description_credit_text }}分</span>
                </div>
                <div>
                  <span>服务</span>
                  <span>{{ shop_info.shop_service_credit_text }}分</span>
                </div>
                <div>
                  <span>发货</span>
                  <span>{{ shop_info.shop_delivery_credit_text }}分</span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="space-around">
        <!--店铺 商品提示-->
        <el-col :span="12">
          <el-card class="box-card">
            <div>
              <h1>店铺及商品提示</h1>
              <h2>您需要关注的店铺信息以及待处理事项</h2>
            </div>
            <div class="store-index-content">
              <p class="store-rompt" @click="toGoodsManager(1)">
                出售中的商品：
                <span style="color: red;">{{ dashBoardData.market_goods }}</span>
              </p>
              <div>
                <el-tag
                  type="success"
                  @click.native="toGoodsManager(0)"
                >仓库待上架货品 {{ dashBoardData.pending_goods }}
                </el-tag>
                <el-tag
                  type="success"
                  @click.native="toConsumerMsg"
                >待回复的咨询 {{ dashBoardData.pending_member_ask }}
                </el-tag>
              </div>
            </div>
          </el-card>
        </el-col>
        <!--商城公告-->
        <el-col :span="12">
          <el-card class="box-card">
            <div>
              <h1>商城公告</h1>
              <h2>您可以关注商城公告，以便能更快的了解商城的信息</h2>
            </div>
            <p class="store-bulletin" v-for="(row,index) in shop_announcement" :key="index">
              》
              <a @click="showArticleContext(row)" :title="row.content">{{ row.article_name }}</a>
            </p>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20" type="flex" justify="space-around">
        <!--交易提示-->
        <el-col :span="12">
          <el-card class="box-card">
            <div>
              <h1>交易提示</h1>
              <h2>您需要立即处理的交易订单</h2>
            </div>
            <div class="store-index-content">
              <p class="store-rompt" @click="toOrderList('ALL')">
                所有的订单：
                <span style="color: red;">{{ dashBoardData.all_orders_num }}</span>
              </p>
              <div>
                <el-tag
                  type="success"
                  @click.native="toOrderList('WAIT_PAY')"
                >待付款 {{ dashBoardData.wait_pay_order_num }}
                </el-tag>
                <el-tag
                  type="success"
                  @click.native="toOrderList('WAIT_SHIP')"
                >待发货 {{ dashBoardData.wait_ship_order_num }}
                </el-tag>
                <el-tag
                  type="success"
                  @click.native="toOrderList('WAIT_ROG')"
                >待收货 {{ dashBoardData.wait_delivery_order_num }}
                </el-tag>
                <el-tag
                  type="success"
                  @click.native="toRefundOrderList()"
                >申请售后 {{ dashBoardData.after_sale_order_num }}
                </el-tag>
              </div>
            </div>
          </el-card>
        </el-col>
        <!--平台联系方式-->
        <el-col :span="12">
          <el-card class="box-card">
            <div>
              <h1>平台联系方式</h1>
              <h2>可以致电平台联系电话或将建议、问题提交到平台邮箱中</h2>
            </div>
            <ul class="platform-concate">
              <li v-if="concat">
                <span>{{ concat.article_name }}</span>：
                <span v-html="concat.content"></span>
              </li>
            </ul>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog :title="currentName" :visible.sync="isShowArticle" width="30%" align="center">
        <p v-html="currentContent.replace(/\n/g, '<br>')"></p>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import * as API_Dashboard from '@/api/dashboard';
import * as API_shop from '@/api/shop';
import * as API_trafficStatistics from '@/api/trafficStatistics';

export default {
  name: 'dashboard',
  mounted() {
    /** 获取首页统计信息 */
    this.GET_DashBoard();

    /** 获取首页商城公告 */
    this.GET_Notice();

    /** 获取首页联系方式 */
    this.GET_Concate();

    //获取当前时间
    var now = new Date();
    var monthn = now.getMonth() + 1;
    var yearn = now.getFullYear();

    this.selectMonth = yearn + '-' + monthn;

    window.onresize = this.countTableHeight;

    if (1) {
      this.$nextTick(() => {
        this.sesalChart = this.$echarts.init(
          document.getElementById('trafficStatistics')
        );
      });
      window.onresize = () => {
        this.sesalChart.resize();
      };
      this.handleShopSumaryStatistics();
    }
  },
  data() {
    return {
      is_JdSupplier: this.$store.getters.shopInfo.shop_id === 66,
      aaaaa: {
        zzzzz: ''
      },
      selectMonth: '',
      sesalChart: '',
      orderTotalNum: '0',
      name: '订单统计',
      /** 加载中*/
      loading: false,

      /** 商家基本信息 */
      shop_info: this.$store.getters.shopInfo,

      /** 首页统计数据*/
      dashBoardData: {},

      /** 商城公告*/
      shop_announcement: [],

      /** 平台联系方式*/
      concat: null,

      /** 商家图标*/
      fileList: [],

      /** 视图高度*/
      tableHeight:
        (document.body.clientHeight - 84 - 80 - 80 - 20 - 20 - 4) / 2,

      /** 当前商城公告名称 */
      currentName: '',

      /** 当前商城公告内容 */
      currentContent: '',

      /** 是否显示商城公告 默认不显示 */
      isShowArticle: false,
      params: {
        year: '2020',
        month: '6',
        cycle_type: 'MONTH'
      }
    };
  },
  computed: {
    /** 用户信息 */
    user_info() {
      return this.$store.getters.user;
    }
  },
  methods: {
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight =
        (document.body.clientHeight - 84 - 80 - 80 - 20 - 20 - 4) / 2;
    },
    /** 店铺总流量*/
    handleShopSumaryStatistics() {
      // console.log(params,"dddd")
      var str = this.selectMonth.split('-');

      this.params.year = Number(str[0]);
      this.params.month = Number(str[1]);
      console.log(str, 'resresresres');
      API_Dashboard.getOrderNum(this.params).then(response => {
        this.orderTotalNum = response.orderTotalNum;
        this.name = response.chart.series.name + '订单统计';
        this.loading = false;
        this.sesalChart.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          grid: {
            left: '40px',
            right: '20px',
            top: '10%',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: response.chart.xAxis
          },
          yAxis: {
            name: '本月订单统计',
            type: 'value'
          },
          series: [
            {
              type: 'line',
              data: response.chart.series.data,
              symbol: 'none', //这句就是去掉点的
              smooth: true, //这句就是让曲线变平滑的
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: '#1A43A9'
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#c2bbfe'
                    },
                    {
                      offset: 1,
                      color: '#d3edfb'
                    }
                  ])
                }
              },
              areaStyle: {
                normal: {}
              },
              barWidth: 50
            }
          ]
        });
      });
    },

    /** 获得首页统计信息*/
    GET_DashBoard() {
      this.loading = true;
      API_Dashboard.getDashboardData().then(response => {
        this.loading = false;
        this.dashBoardData = response;
      });
    },

    /** 获取首页 商城公告 */
    GET_Notice() {
      this.loading = true;
      const category_type = 'NOTICE';
      API_Dashboard.getNotice(category_type, {}).then(response => {
        this.loading = false;
        this.shop_announcement = response;
      });
    },

    /** 获取首页 平台联系方式 */
    GET_Concate() {
      this.loading = true;
      API_Dashboard.getConcate({position: 'CONTACT_INFORMATION'}).then(
        response => {
          this.loading = false;
          this.concat = response;
        }
      );
    },

    /** 店铺LOGO上传 */
    toChangeShopIcon() {
      this.$refs.uploadBtn.click();
    },

    /** 图片上传之前的校验 */
    handleShopLogoBefore(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = event => {
          let image = new Image();
          image.onload = () => {
            let width = image.width;
            let height = image.height;
            if (width > 300 || width < 200) {
              this.$message.error('图片宽度必须在200~300之间！');
              reject();
            }
            if (height > 100 || height < 50) {
              this.$message.error('图片高度必须在50~100之间！');
              reject();
            }
            resolve();
          };
          image.src = event.target.result;
        };
        reader.readAsDataURL(file);
      });
    },

    /** 上传成功后的钩子 更换图片 置空存储数组*/
    uploaded(response) {
      this.shop_info.shop_logo = response.url;
      this.fileList = [];
      API_shop.updateShopLogo({logo: response.url}).then(() => {
        this.$message.success('店铺LOGO修改成功');
        this.$store.dispatch('getShopInfoAction');
      });
    },

    /** 跳转商品列表*/
    toGoodsManager(goodsStatus) {
      this.$router.push({
        path: '/goods/goods-list',
        query: {market_enable: goodsStatus}
      });
    },

    /** 跳转买家留言 待回复的咨询 */
    toConsumerMsg() {
      this.$router.push({
        path: '/customer/consultation',
        query: {reply_status: 0}
      });
    },

    /** 跳转订单列表*/
    toOrderList(orderStatus) {
      this.$router.push({
        path: '/order/order-list',
        query: {order_status: orderStatus}
      });
    },

    /** 跳转维权订单*/
    toRefundOrderList() {
      this.$router.push({path: '/order/refund-list'});
    },

    /** 显示商城公告 */
    showArticleContext(row) {
      this.currentName = row.article_name;
      this.currentContent = row.content;
      this.isShowArticle = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-card {
  .header {
    .col-auto {
      img {
        width: 26px;
        height: 26px;
      }
    }

    .col {
      padding-left: 12px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .cs {
    padding-top: 32px;
    transform: scaleY(1.1);
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  }
}

.box span {
  vertical-align: middle;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-container {
  height: 100%;
}

/deep/ .el-row {
  position: relative;
}

.el-col {
  border-radius: 4px;
}

/*商家基本信息*/
div.grid-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;

  /*logo*/
  .logo {
    flex-grow: 1;
    padding: 15px;
    position: relative;
    box-sizing: content-box;

    div.shop-img-icon {
      width: 200px;

      &:hover span.to-change-shop-icon {
        display: block;
      }

      span.to-change-shop-icon {
        display: none;
        position: absolute;
        left: 16px;
        bottom: 20px;
        z-index: 10;
        width: 200px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        background: rgba(33, 33, 33, 0.6);
        color: #fff;
        cursor: pointer;
      }

      img {
        width: 200px;
        height: 80px;
        cursor: pointer;
        border: 1px solid #999;
      }
    }
  }

  /*商家信息*/
  .shop-info {
    flex-grow: 40;
    padding-top: 30px;
    /*商家主营信息*/
    .shop-info-basic {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      span:first-child {
        color: #333;
        font: 18px/36px "microsoft yahei";
        letter-spacing: normal;
        vertical-align: bottom;
        word-spacing: normal;
      }

      span:last-child {
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        margin-left: 12px;
        vertical-align: bottom;
        word-spacing: normal;
      }
    }

    /*卖家信用*/
    .shop-info-credit {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      /deep/ span {
        display: inline-block;
        margin-left: 0 !important;
        color: #999;
        font-size: 12px;
        letter-spacing: normal;
        line-height: 28px;
        vertical-align: bottom;
        word-spacing: normal;
      }

      .shop-icon {
        margin: 0 40px;
      }
    }
  }

  /*动态评分*/
  .score {
    flex-grow: 2.5;
    text-align: right;
    padding: 5px 20px;

    p {
      margin: 0;
      text-align: left;
      border-bottom: 1px dotted #e7e7e7;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding-bottom: 5px;
    }

    div.active-score {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      margin-top: 5px;

      div {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;

        span:first-child {
          color: #333;
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          font-weight: bold;
        }

        span:last-child {
          display: inline-block;
          font-size: 14px;
          text-align: center;
          padding: 5px 0px;
          color: #da542e;
        }
      }
    }
  }
}

/* 店铺商品提示/交易订单提示*/
h1 {
  border-left: 3px solid #28b779;
  font: 16px/18px "microsoft yahei";
  height: 18px;
  margin-bottom: 4px;
  padding-left: 6px;
  margin-left: 17px;
}

/*副标题*/
h2 {
  color: #aaa;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;
}

/* 店铺/交易提示内容 */
.store-index-content {
  margin: 20px 0;
  overflow: hidden;

  .store-rompt {
    clear: both;
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    cursor: pointer;
  }

  /** 标签 */
  .el-tag {
    cursor: pointer;
  }
}

/*商城公告*/
.store-bulletin {
  color: #aaa;
  cursor: pointer;
  font: 12px/16px "microsoft yahei";
  margin: 10px 0;

  &:hover a {
    color: #337ab7;
  }
}

/*平台联系方式*/
.platform-concate {
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    color: #aaa;
    font: 12px/16px "microsoft yahei";
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-block;

      /deep/ p {
        margin: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.box > div {
  display: inline-block;
}

.dashboard-container {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tip {
  margin: 20px 14px;
  background: #eee;
  line-height: 36px;
  padding-left: 20px;
}

.tip span {
  color: red;
}

.link /deep/ .el-card__body {
  min-height: 0;
}

.order {
  overflow: hidden;
}

.order .orderLeft {
  float: left;
  width: 200px;
  padding: 30px;
  border-right: 1px solid #eee;
  height: 390px;
}

.order .orderRight {
  float: right;
  width: calc(100% - 200px);
}

.orderBox {
  margin-bottom: 70px;
}

.orderBox h2 {
  font-size: 18px;
  color: #999;
  line-height: 20px;
  margin: 0;
}

.orderBox h3 {
  font-size: 24px;
  color: #393c41;
  font-weight: 600;
  line-height: 42px;
  margin: 0;
}

.orderBox p {
  font-size: 16px;
  color: #ccc;
  line-height: 20px;
  margin: 0;
}

.orderBox span.red {
  vertical-align: text-top;
  color: rgb(240, 72, 68);
  background: url(../../assets/images/icon9.svg) no-repeat left center;
  padding-left: 10px;
  margin-right: 5px;
}

.orderBox span.green {
  vertical-align: text-top;
  color: rgb(26, 188, 156);
  background: url(../../assets/images/icon8.svg) no-repeat left center;
  padding-left: 10px;
  margin-right: 5px;
}

.orderTab {
  padding-right: 20px;
}

.orderTab h4 {
  line-height: 32px;
  margin: 0;
  text-indent: 40px;
  font-size: 18px;
  color: #393c41;
}

.tabBtn {
  float: right;
}

.tabBtn span {
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #393c41;
}

.tabBtn span.active {
  color: rgb(26, 188, 156);
}

.boxCard {
  border-radius: 10px;
  background: #fff;
  padding-left: 36px;
}

.boxCard .hr {
  width: 552px;
  background: #f5f6f9;
  height: 1px;
  margin: 5px auto 5px;
}

.boxCard .box {
  padding-bottom: 40px;
  padding-top: 40px;
}

.boxCard .box h2 {
  padding-top: 42px;
  padding-left: 40px;
  font-size: 30px;
  color: #393c41;
  font-weight: 600;
  line-height: 48px;
  margin: 0;
}

.boxCard .box h2 img {
  width: 48px;
  height: 48px;
  margin-right: 22px;
  margin-top: -4px;
}

.quickLink {
  margin: 20px 0;
}

.title {
  color: #393c41;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

.title:before {
  content: " ";
  display: block;
  width: 4px;
  height: 12px;
  background: #1a43a9;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 0;
}

.linkBox {
  font-size: 0;
  line-height: 0;
}

.linkBox a {
  display: inline-block;
  width: 120px;
  height: 49px;
  border-radius: 5px;
  line-height: 49px;
  font-size: 9px;
  color: #393c41;
  margin-right: 10px;
  background: #fff;
  text-align: center;
  margin-bottom: 0px;
}

.linkBox a:last-child {
  margin-right: 0;
}

.linkBox a img {
  margin-right: 9px;
  margin-top: -4px;
}

.app ul {
  font-size: 0;
  line-height: 0;
  list-style: none;
  padding: 0;
}

.app ul li {
  display: inline-block;
  width: calc(20% - 16px);
  text-align: center;
  box-sizing: border-box;
  padding: 40px 26px 20px;
  background: #fff;
  border-radius: 10px;
  height: 480px;
  margin-right: 20px;
}

.app ul li:last-child {
  margin-right: 0;
}

.app ul li img {
  display: block;
  width: 118px;
  height: 118px;
  margin: auto auto 10px;
}

.app ul li h4 {
  font-size: 18px;
  color: #393c41;
  line-height: 40px;
}

.app ul li p {
  text-align: left;
  font-size: 16px;
  color: #585c66;
  line-height: 28px;
  height: 116px;
}

.app ul li span {
  display: block;
  font-size: 18px;
  color: #333333;
  margin-bottom: 22px;
  line-height: 18px;
}

.order {
  background: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  margin: 0;
}
</style>
